<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="reset()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Download</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="save" method="post" id="download-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-file-input
            label="Thumbnail Image"
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            outlined
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
          ></v-file-input>
          <v-file-input
            label="File Upload"
            v-model="fields.file"
            accept="application/pdf"
            placeholder="Choose a File"
            outlined
            @change="fileHasChanged()"
            :error="errors.hasOwnProperty('file')"
            :error-messages="errors['file']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="download-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        thumbnail: null,
        thumbnail_has_changed: false,
        file: null,
        file_has_changed: false,
      },
      errors: {},
    };
  },

  methods: {
    open(download = null) {
      if (download !== null) {
        this.isEditing = true;
        this.download = download;
        this.fields.name = this.download.name;

        this.fields.thumbnail = this.download.thumbnail
          ? new File([this.download.thumbnail], this.download.thumbnail)
          : null;

        this.fields.file = this.download.file
          ? new File([this.download.file], this.download.file)
          : null;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.name = "";
      this.fields.thumbnail = null;
      this.fields.thumbnail_has_changed = false;
      this.fields.file = null;
      this.fields.file_has_changed = false;

      this.errors = {};
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.downloadId = this.download.id;
      }

      this.$store
        .dispatch("grogreen/articles/saveDownload", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    fileHasChanged: function () {
      this.fields.file_has_changed = true;
    },

    thumbnailHasChanged: function () {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
